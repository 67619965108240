import LoaderOverlay from './LoaderOverlay.vue'
import { events } from './events'

const Loader = {
  install (Vue, args = {}) {
    if (this.installed) {
      return
    }

    this.installed = true
    this.params = args
    // let events = new Vue();
    Vue.component('loaderOverlay', LoaderOverlay)

    const updateloading = (params) => {
      events.$emit('updateloading', params)
    }

    Vue.prototype['$' + 'updateloading'] = updateloading
    Vue.updateloading = updateloading
  }
}

export default Loader
