<template>
  <b-container
    fluid="sm"
    class="h-100"
  >
    <LoginForm
      v-if="!isLoggedIn"
      @login="init"
    >
    </LoginForm>

    <b-row v-else>
      <b-col>
        <!-- <ul style="font-size: 150%;">
          <li
            v-if="hasRole(10) || hasRole(20)"
            class="p-5 list-unstyled"
          ><a href="/quickbooks">Quickbooks Revenue Uploads</a></li>
        </ul> -->
        <ul style="font-size: 150%;">
          <li
            v-if="hasRole(10) || hasRole(20)"
            class="p-5 list-unstyled"
          ><a href="/payments">Payment Coding and Export</a></li>
        </ul>
        <ul style="font-size: 150%;">
          <li
            v-if="hasRole(10) || hasRole(20)"
            class="p-5 list-unstyled"
          ><a href="/wellsfargo">Wells Fargo File Upload</a></li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import UserService from '@/services/UserService'
import LoginForm from '@/components/Login'

export default {
  name: 'HomeView',
  components: { LoginForm },

  data () {
    return {
      isLoggedIn: false
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.isLoggedIn = UserService.isLoggedIn()
    },

    logout () {
      UserService.logout()
      this.init()
    },

    hasRole (roleId) {
      return UserService.hasRole(roleId)
    }
  }
}
</script>
