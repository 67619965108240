import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker' !!!tmp fixing deployment !!!
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Loader from './plugins/LoaderOverlay'
import Notifications from 'vue-notification'
import DefaultLayout from './layouts/DefaultLayout'
import aimNotify from './plugins/aim-notify'

// bootstrap 4
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import UserService from '@/services/UserService'

Vue.config.productionTip = false

Vue.use(Loader)
Vue.use(Notifications)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(aimNotify)
Vue.component('default-layout', DefaultLayout)

export const bus = new Vue()

let apiUrl = 'https://ccfh-portal-api.dev.aimitservices.com/'
if (window.location.host.match(/dopccfh\.org/)) {
  apiUrl = 'https://portal-api.dopccfh.org/'
} else if (window.location.host.match(/staging\.aimit\.io/)) {
  apiUrl = process.env.VUE_APP_STAGING_API_URL
}

// set api url
Vue.axios.defaults.baseURL = apiUrl
// set axios defaults to always set content type to JSON by default.
Vue.axios.defaults.headers.common['Content-Type'] = 'application/json'
Vue.axios.defaults.headers.common.Accept = 'application/json'
Vue.axios.defaults.withCredentials = true
if (process.env.NODE_ENV === 'development') {
  Vue.axios.defaults.params = {}
  Vue.axios.defaults.params.XDEBUG_SESSION = 'mobile'
}

const convertToCurrency = (value) => {
  if (!value) value = 0
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  return formatter.format(parseFloat(value))
}
Vue.prototype['$' + 'convertToCurrency'] = convertToCurrency
Vue.convertToCurrency = convertToCurrency
Vue.filter('currency', convertToCurrency)

// nothing defined here (when this.$route.path is other than "/")
// intercept all the axios request
Vue.axios.interceptors.response.use(
  // handle response here
  (response) => {
    return Promise.resolve(response)
  },
  (err) => {
    if (typeof err.response === 'undefined') {
      throw new Error('An internal error has occurred.')
    } else {
      // KNOW WHY THIS EXISTS AND WHAT IT'S FOR BEFORE TRYING TO IMPLEMENT
      // const unAuthErrorCode = [401, 403]
      // if (unAuthErrorCode.includes(err.response.status)) {
      //   // this.logout()
      //   return Promise.reject(err.response)
      // } else if (err.response.status === 425) {
      //   // efish service suspended or cancelled
      //   this.$router.push('/suspended')
      // } else {
      throw err
      // }
    }
  }
)

// Ensuring this call completes before we allow Vue to do anything
UserService.getUserInfo().then(() => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
})
