<template>
  <div id="app">
    <notifications
      group="errors"
      :ignoreDuplicates="true"
      :max="1"
      role="status"
    ></notifications>
    <notifications
      group="notices"
      :ignoreDuplicates="true"
      :max="1"
      role="status"
    ></notifications>
    <NavigationView></NavigationView>
    <router-view />
  </div>
</template>
<script>
import NavigationView from '@/components/Navigation.vue'

export default {
  name: 'MainApp',
  components: { NavigationView }
}
</script>
<style lang="scss">
body {
  min-height: 100vh;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
