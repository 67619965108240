<template>
  <b-navbar
    v-if="isLoggedIn"
    class="py-0 border-bottom mb-5"
    toggleable="lg"
    type="light"
    role="region"
    aria-label="sidebar"
  >
    <b-navbar-brand to="/">
      <a
        to="/"
        class="sbn-navbar-brand-logo"
      ><img
          src="/img/ccfh.png"
          class="img-responsive"
          style="max-height: 50px;"
        ></a>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse
      id="nav-collapse"
      is-nav
    >
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <b>{{ userInfo.username }}</b>
          </template>

          <!-- <b-dropdown-item
            to="/quickbooks"
            v-if="hasRole(10) || hasRole(20)"
          >Quickbooks Revenue Uploads</b-dropdown-item> -->
          <b-dropdown-item
            to="/payments"
            v-if="hasRole(10) || hasRole(20)"
          >Payment Coding and Export</b-dropdown-item>
          <b-dropdown-item
            to="/wellsfargo"
            v-if="hasRole(10) || hasRole(20)"
          >Wells Fargo Payment Upload</b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item to="/logout">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
import { bus } from '@/main'
import UserService from '@/services/UserService'

export default {
  name: 'NavigationView',

  data () {
    return {
      userInfo: null
    }
  },

  async mounted () {
    await this.getUserInfo()
    // when the user logs in, check the user info again
    // this is used for setting nav status
    bus.$on('login', () => {
      this.getUserInfo()
    })
  },

  computed: {
    /**
     * @returns {Boolean}
     */
    isLoggedIn: function () {
      return (this.userInfo && +this.userInfo.id > 0)
    }
  },

  methods: {
    async getUserInfo () {
      this.userInfo = await UserService.getUserInfo()
    },

    hasRole (roleId) {
      return UserService.hasRole(roleId)
    }
  }
}
</script>
