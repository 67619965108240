import Vue from 'vue'
import router from '@/router' // vue router won't work through Vue.prototype

const _aimNotify = {

  notify (response, type, title, text) {
    return this._handleResponse(response, this._createNotification(response, type, title, text))
  },

  error (response, type, title, text) {
    const notification = this._createNotification(response, type || 'error', title, text)
    return this._handleResponse(response, notification)
  },

  _handleResponse (response, notification) {
    if (response && response.data && response.data.redirect) {
      // we have a redirect, LFG
      router.push(response.data.redirect)
    }
    return this._sendNotification(notification)
  },

  _sendNotification (notification) {
    if (notification.text) {
      return Vue.prototype.$notify(notification)
    } else {
      console.log('Empty notification text, not sending')
    }
  },

  _createNotification (response, type, title, text) {
    const notification = {
      group: 'notices',
      title: title || null,
      text: text || null,
      type: type || 'info'
    }
    if (response && typeof response === 'object' && typeof response.data === 'object') {
      if (!notification.title) {
        if (typeof response.data.header === 'string') {
          notification.title = response.data.header
        } else {
          notification.title = 'Error'
        }
      }

      // check for messages
      if (!notification.text) {
        if (response.data.messages instanceof Array && response.data.messages.length) {
          notification.text = this._getMessage(response.data.messages)
        } else if (typeof response.data.messages === 'string') {
          notification.text = response.data.messages // plain text message
        }
      }

      // check for error messages
      if (response.data.errors instanceof Array && response.data.errors.length) {
        if (!type) {
          notification.type = 'error'
        }
        if (!notification.text) {
          notification.text = this._getMessage(response.data.errors)
        }
      } else if (!notification.text && typeof response.data.errors === 'string') {
        notification.text = response.data.errors
      } else if (!notification.text && typeof response.data.error === 'string') {
        notification.text = response.data.error
      }
    }
    return notification
  },

  _getMessage (data) {
    let msg = ''
    if (data instanceof Array) {
      data.forEach((m) => {
        msg += m + ' '
      })
    } else {
      msg = data
    }
    return msg
  }
}

Plugin.install = function (Vue) {
  Vue.$aimNotify = _aimNotify
  window.$aimNotify = _aimNotify
  Object.defineProperties(Vue.prototype, {
    aimNotify: {
      get () {
        return _aimNotify
      }
    },
    $aimNotify: {
      get () {
        return _aimNotify
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
