import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

import UserService from '@/services/UserService'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Portal', anonymous: true }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('../views/Forgot.vue'),
    meta: { title: 'Recover Password', anonymous: true }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/Logout.vue'),
    meta: { title: 'Log Out', anonymous: false }
  },
  // {
  //   path: '/quickbooks',
  //   name: 'quickbooks',
  //   component: () => import('../views/QuickBooks.vue'),
  //   meta: { title: 'Quickbooks Upload', anonymous: false }
  // },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('../views/Payments.vue'),
    meta: { title: 'Payments', anonymous: false }
  },
  {
    path: '/wellsfargo',
    name: 'wellsfargo',
    component: () => import('../views/WellsFargo.vue'),
    meta: { title: 'Wells Fargo Upload', anonymous: false }
  }
]

// instantiate vue router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// check meta tags and permissions
router.beforeEach(async (to, from, next) => {
  const loggedIn = UserService.isLoggedIn()
  if (!to.meta.anonymous && !loggedIn) {
    return next({
      path: '/',
      query: { redirect: to.fullPath }
    })
  }

  const isAdmin = UserService.isAdmin()
  if (to.meta.admin && !isAdmin) {
    console.log('not admin redirecting')
    return next({
      path: '/'
    })
  }

  to.meta.layout = 'default'

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
