<template>
  <b-form
    method="POST"
    @submit.prevent="login"
  >
    <b-row
      align-h="center"
      class="h-100 mt-5"
    >
      <b-col>
        <b-img
          fluid
          src="/img/ccfh.png"
          class="mx-auto mb-5"
        ></b-img>

        <b-form-group
          label-for="ccfhUsername"
          label="Username:"
          label-align="left"
          label-align-sm="right"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm="8"
          content-cols-lg="7"
        >
          <b-form-input
            id="ccfhUsername"
            type="text"
            v-model="form.username"
            placeholder="Username or Email Address"
            autofocus
            v-on:keyup.native.enter.prevent="focusPassword"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="ccfhPassword"
          label="Password:"
          label-align="left"
          label-align-sm="right"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm="8"
          content-cols-lg="7"
        >
          <b-form-input
            id="ccfhPassword"
            ref="passwordInput"
            type="password"
            v-model="form.password"
            placeholder="Password"
            required
          ></b-form-input>
        </b-form-group>

        <b-button
          type="submit"
          :disabled="loading"
          variant="success"
        >Log In</b-button>

        <p class="m-5">
          <router-link to="/forgot">Forgot Your Password?</router-link>
        </p>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import UserService from '@/services/UserService'

export default {
  name: 'LoginForm',

  data () {
    return {
      loading: false,
      form: {
        username: null,
        password: null
      }
    }
  },

  methods: {
    focusPassword () {
      this.$refs.passwordInput.focus()
    },

    login () {
      if (this.loading) {
        return false
      }
      if (!this.form.username || !this.form.password) {
        this.$aimNotify.error(null, 'error', 'Invalid Data', 'Username and password are required!')
        return false
      }
      this.loading = true
      UserService.login(this.form.username, this.form.password).then((response) => {
        this.$aimNotify.notify(null, 'notice', 'Log In Successful', 'You have successfully logged in.')
        this.$emit('login')
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
