<template>
  <b-overlay :show="loading > 0">
    <slot></slot>
  </b-overlay>
</template>

<script>
import { events } from './events'
export default {
  data () {
    return {
      loading: 0
    }
  },
  methods: {
    changeLoadingValue (value) {
      this.loading += value
      if (this.loading < 0) {
        this.loading = 0
      }
    }
  },
  mounted () {
    events.$on('updateloading', this.changeLoadingValue)
  }
}
</script>
