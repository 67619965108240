<template>
  <div class="main-app">
    <header>
    </header>
    <main style="padding: 0 15px">
      <content>
        <slot></slot>
      </content>
    </main>
    <footer></footer>
  </div>
</template>

<script>
import UserService from '@/services/UserService'

export default {
  name: 'DefaultLayout',

  data () {
    return {
      info: null
    }
  },

  async mounted () {
    this.info = await UserService.getUserInfo()
  },

  methods: {
  }
}
</script>
