const StorageService = {
  // Retrieve the specified object from local storage
  get (key) {
    const temp = localStorage.getItem(key)
    if (temp && temp !== null) {
      try {
        return JSON.parse(localStorage.getItem(key))
      } catch (error) {
        // invalid localstorage item, delete it and return null
        localStorage.removeItem(key)
        return null
      }
    } else {
      return null
    }
  },

  // Set the specified key to the value of info in local storage
  set (key, info, date = new Date()) {
    localStorage.setItem(key, JSON.stringify(info))
    if (date) {
      localStorage.setItem(key + '_updated', JSON.stringify(date))
    }
  },

  // Remove the specified key and it's _updated timestamp from local storage
  remove (key) {
    localStorage.removeItem(key)
    localStorage.removeItem(key + '_updated')
  },

  // Determine if key data is expired and needs to refresh
  isExpired (key) {
    const lastEntry = this.get(key + '_updated')
    if (!lastEntry) {
      return true
    }
    const last = new Date(lastEntry)
    const curt = new Date()
    if (curt - last >= 5 * 60 * 1000) {
      return true
    }
    return false
  }
}

export default StorageService
