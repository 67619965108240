import axios from 'axios'
import { bus } from '@/main'
import router from '@/router' // vue router won't work through Vue.prototype
import Vue from 'vue'
import StorageService from '@/services/StorageService'

const UserService = {

  // Returns user info object
  async getUserInfo () {
    return await this.verify('user')
  },

  async getRefreshedUser () {
    localStorage.removeItem('user_updated')
    return await this.verify('user')
  },

  async login (username, password, redirect) {
    const data = {
      username: username,
      password: password,
      redirect: redirect || null,
      locale: window.navigator.language
    }

    return axios({
      url: axios.defaults.baseURL + 'login',
      method: 'POST',
      data: JSON.stringify(data)
    }).then(async (response) => {
      if (response && response.data && response.data.result) {
        StorageService.set('user', response.data.info)
        this.setHeaders()
        bus.$emit('login')
        bus.$emit('user', new Date())
      }
      return response
    })
  },

  async logout () {
    delete axios.defaults.headers.common.Authorization
    StorageService.remove('user')
    Vue.prototype.$aimNotify.notify(null, 'info', 'Logged Out', 'You are now logged out.')
    bus.$emit('logout')
    router.push('/').catch(() => {
      window.location.href = '/' // hard reload if push refuses
    })
  },

  async verify (key) {
    this.setHeaders()
    const info = StorageService.get(key)
    if (info && !StorageService.isExpired(key)) {
      return info
    } else {
      const response = await this.refresh()
      if (response !== null) {
        StorageService.set('user', response.data.info)
        bus.$emit('user', new Date())
        return response.data.info
      }
    }
    return null
  },

  async refresh () {
    return axios({
      url: axios.defaults.baseURL + 'login/verify',
      method: 'post'
    }).then(success => {
      return Promise.resolve(success)
    }, error => {
      // because of how this is being used, the await is always
      // expecting a resolve return, so let's give them one
      Vue.prototype.$aimNotify.error(error.response)
      return Promise.resolve(null)
    })
  },

  isLoggedIn () {
    const userInfo = StorageService.get('user')
    return (userInfo && userInfo.apitoken)
  },

  isAdmin () {
    const userInfo = StorageService.get('user')
    return (userInfo && userInfo.isAdmin)
  },

  hasRole (roleId) {
    const userInfo = StorageService.get('user')
    let hasRole = false
    if (userInfo && userInfo.roles && userInfo.roles.length) {
      userInfo.roles.some((r) => {
        if (+r.role_id === +roleId) {
          hasRole = true
          return true
        } else return false
      })
    }
    return hasRole
  },

  setHeaders () {
    const userInfo = StorageService.get('user')
    if (userInfo && userInfo.apitoken) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + userInfo.apitoken
    } else {
      // remove any possible authorization header if user is invalid
      delete axios.defaults.headers.common.Authorization
    }
  },

  recover (username, email) {
    return axios({
      url: axios.defaults.baseURL + 'login/recover',
      method: 'POST',
      data: JSON.stringify({ username: username, email: email })
    })
  }
}

export default UserService
